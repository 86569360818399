<template>
  <div class="bar-container">
    <vue-c3
      id="horizontalChart"
      ref="bar-chart"
      :handler="handler"
      class="bar-chart-canvas"
      :class="`${columns?.length * BAR_WIDTH < height ? 'overflowHidden' : ''}`"
    ></vue-c3>
  </div>
</template>

<script>
import Vue from 'vue';
import VueC3 from 'vue-c3';
import * as d3 from 'd3';
import * as CONSTANT from '~/constant';
import { formatNumberAsFullNumber } from '~/util/numeral';

const sampleData = [];

export default {
  name: 'HorizontalBarChart',
  components: {
    VueC3,
  },
  props: {
    rows: {
      type: Array,
      required: false,
      default: () => sampleData,
    },
    valueType: {
      type: String,
      required: false,
      default: () => '',
    },
    height: {
      type: Number,
      required: false,
      default: () => 240,
    },
    width: {
      type: Number,
      required: false,
      default: () => null,
    },
  },
  data: () => {
    return {
      BAR_WIDTH: 34,
      handler: new Vue(),
    };
  },
  computed: {
    columns() {
      const yEntries = ['x', 1];
      const data = [];

      data.push(yEntries);
      data.push(...this.rows);

      return data;
    },
    options() {
      const vm = this;
      return {
        color: {
          pattern: CONSTANT.COLOR_PATTERN,
        },
        data: {
          type: 'bar',
          x: 'x',
          columns: [],
        },
        legend: {
          show: false,
        },
        size: {
          height:
            vm.columns?.length * vm.BAR_WIDTH < vm.height
              ? vm.height
              : vm.columns?.length * vm.BAR_WIDTH,
          width: vm.width,
        },
        tooltip: {
          grouped: false,
          format: {
            value(value) {
              return `${formatNumberAsFullNumber(value, vm.valueType)}`;
            },
          },
        },
        axis: {
          x: {
            show: false,
          },
          rotated: true,
        },
        onrendered() {
          d3.selectAll('.c3-axis-x .tick text').each(function(d) {
            let title = d3.select(this).select('title');
            if (title.empty()) {
              title = d3.select(this).append('title');
            }
            title.text(vm.columns[d + 1]);
          });
          const graphElement = document.getElementById('horizontalChart');
          if (graphElement) {
            graphElement.scrollTop = graphElement.scrollHeight;
          }
        },
        bar: {
          width: vm.BAR_WIDTH,
        },
      };
    },
  },
  watch: {
    columns() {
      const vm = this;

      vm.render();
    },
  },
  mounted() {
    const vm = this;

    vm.render();
  },
  beforeDestroy() {
    const vm = this;

    vm.handler.$emit('destroy');
  },
  methods: {
    render() {
      const vm = this;
      vm.handler.$emit('destroy');
      vm.handler.$emit('init', vm.options);
      vm.handler.$emit('dispatch', (chart) => {
        chart.load({
          columns: vm.columns,
        });
      });
    },
  },
};
</script>

<style lang="scss">
.overflowHidden {
  overflow: hidden;
}
.bar-chart-canvas {
  height: 208px;
  max-height: 208px !important;
  overflow: auto;
  display: flex;
  margin-top: 28px;
  margin-bottom: 2px;
}
.c3-chart-arc path {
  stroke: rgba(180, 180, 180, 0.2) !important;
}
.c3-tooltip {
  background-color: #1a1b1d;
  padding: 10px 15px;
  width: 190px;
  font-size: 11px;
  margin-top: -37px;
  border-radius: 4px;
  opacity: 0.96;
  box-shadow: none;
}
.c3-tooltip th {
  background-color: rgba(0, 0, 0, 0.1);
  font-size: 13px;
  padding: 2px 5px;
  text-align: left;
  border-left: 1px solid rgba(255, 255, 255, 0.05);
  color: #fff;
  font-weight: 400;
}
.c3-tooltip td {
  font-size: 11px;
  padding: 3px 6px;
  background-color: transparent;
  border-left: 1px solid rgba(255, 255, 255, 0.05);
  color: #999;
}
.c3-tooltip tr {
  border: 0px solid #ccc;
}
.bar-chart-canvas svg .c3-axis-y .tick {
  font: 11px sans-serif;
}
.bar-chart-canvas svg .c3-axis-y .tick:nth-of-type(1) {
  transform: translate(2px, 0px);
}

.c3-tooltip-container .c3-tooltip tbody tr th {
  display: none;
}
.c3-tooltip-container .c3-tooltip tbody tr td {
  width: max-content;
}
.c3 line,
.c3 path {
  stroke: rgba(180, 180, 180, 0.2);
}
.c3 text {
  fill: #7e8890;
}
</style>
<style lang="scss" scoped>
.c3 svg {
  font: 11px;
  /*font-family: 'Source Sans Pro', sans-serif;*/
}
/*.c3-line {stroke: #f00 !important;}*/

.c3-line {
  stroke-width: 3px;
}

.noy .c3-axis-y .tick text {
  opacity: 0;
}
.c3-legend {
  margin-top: 20px;
}
.c3-legend-item-tile {
  border-radius: 10px;
}
.c3-shapes circle {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.9);
}

.c3 circle {
  stroke: rgba(222, 222, 222, 0);
  stroke-width: 1px;
  fill: rgba(222, 222, 222, 0);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.c3-circle._expanded_ {
  stroke: rgba(170, 170, 170, 0.7) !important;
  stroke-width: 2px;
  fill: rgba(222, 222, 222, 0) !important;
}

.c3-chart-arc path {
  stroke: rgba(0, 0, 0, 0.1);
}
</style>
